import React, { useState, useEffect } from "react"
import { Section } from "../components/Section"
import * as keyartStyle from "../styles/keyart.module.css"
import { Fade } from "react-awesome-reveal"
import { useI18next } from "gatsby-plugin-react-i18next"

export function Keyart() {
  const { language, t } = useI18next()
  return (
    <Section bg="bg-fl-keyart" color="text-fl-white" className="pt-0 pb-6 -mb-6 md:mb-0">
      <Fade triggerOnce delay={100} duration={800} className="opacity-0">
        <div className={keyartStyle.container}>
          <div className="max-w-full overflow-hidden pt-2">
            <Image />
          </div>
        </div>
        <h1 className="flex items-center flex-col relative -top-24 -mb-10">
          <img
            src={`/assets/title_subtitle${language === "de" ? "_de" : ""}.png`}
            alt={t("headTitle", "Frameland – A short RPG with a big heart.")}
            width={410}
            height={110}
          />
        </h1>
      </Fade>
    </Section>
  )
}

function Image() {
  return (
    <picture className={keyartStyle.container}>
      <source srcSet="/assets/keyart_full.png" media="(min-width: 768px)" />
      <img
        src="/assets/keyart_mobile.png"
        alt="Keyart with heroes and villains"
        width={842}
        height={843}
        className={keyartStyle.keyartImage}
      />
    </picture>
  )
}
