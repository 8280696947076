import React from 'react';
import { ContentContainer } from '../components/Container';
import { Trans } from 'gatsby-plugin-react-i18next';

const tracks = [
	{
		name: 'Hammertal',
		file: 'happy_theme'
	},
	{
		name: 'Fight the Swarm',
		file: 'battle'
	},
	{
		name: 'Between a Rock and a Hard Place',
		file: 'slums'
	},
];

export function Music(props) {
	return (
		<ContentContainer className='pt-8 mt-16 text-fl-text max-w-sm xl:max-w-5xl overflow-hidden'>
			<h2 className="text-lg uppercase tracking-wider text-fl-white text-center mb-0">
				<Trans i18nKey="musicTitle">
					Music
				</Trans>
			</h2>
			<ul className="block py-3 px-4 xl:flex xl:px-0">
				{tracks.map((track) => (
					<li key={track.file} className="mb-3 mr-2 border border-fl-turkis-dark px-4 pt-2 pb-4 bg-fl-turkis-dark/40">
						<p className="mt-2 mb-1 ml-4">{track.name}</p>
						<audio className="block" controls preload="none">
							<source src={`/assets/music/${track.file}.mp3`}></source>
							<source src={`/assets/music/${track.file}.ogg`}></source>
						</audio>
					</li>
				))}
			</ul>
		</ContentContainer>
	)
}