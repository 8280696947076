import React from "react"
import { Footer } from "../components/Footer"
import { Main } from "../components/Main"
import { Menubar } from "../components/Menu"
import { Head as DefaultHead } from "../head"
import { Battle } from "../sections/Battle"
import { Keyart } from "../sections/Keyart"
import { Social } from "../sections/Social"
import { Intro } from "../sections/Intro"
import { Features } from "../sections/Features"
import { Trailer } from "../sections/Trailer"
import { Music } from "../sections/Music"
import { graphql } from 'gatsby';

function Home() {
	return (
		<>
			<Main>
				<Menubar />
				<Keyart />
				<Trailer />
				<Intro />
				<Battle />
				<Features />
				<Music />
				<Social />
				<Footer />
			</Main>
		</>
	)
}

export function Head({ data }) {
	let obj = data.locales?.edges?.filter(e => e.node.ns === 'index');
	let t;
	if (obj && obj.length === 1) {
		t = JSON.parse(obj[0].node.data);
	}
	const title = t?.headTitle || "Frameland – A short RPG with a big heart";
	const description = t?.headDescription || "Frameland is a game about humans, robots, and what’s in-between & classic turn-based battles with a twist: time your attacks for maximum impact!";

	return (
		<DefaultHead
			title={title}
			description={description}
		>
			<link rel="preload" as="image" href="/assets/keyart_mobile.png" media="(max-width: 768px)"></link>
			<link rel="preload" as="image" href="/assets/keyart_full.png" media="(min-width: 768px)"></link>
		</DefaultHead >
	)
}

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;