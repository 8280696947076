import React, { useState, useEffect } from "react"
import { Box } from "../components/Box"
import { Section } from "../components/Section"
import { SteamButton } from "../components/Buttons"
import { Fade } from "react-awesome-reveal"
import { Modal } from "../components/Modal"

export function Trailer() {
  return (
    <Section bg="bg-fl-keyart" color="text-fl-white" className="pt-0 -mt-8">
      <div className="flex flex-col items-center">
        <TrailerPreview />
      </div>
      <div className="flex justify-center items-center mt-4 md:pb-4">
        <Fade direction="down" damping="1.0">
          <SteamButton />
        </Fade>
      </div>
    </Section>
  )
}

function TrailerPreview(props) {
  const [state, setState] = useState("hidden")

  function showTrailer() {
    setState("shown")
  }

  function hideTrailer() {
    setState("hidden")
  }

  return (
    <>
      <div
        className="drop-shadow-2xl border-4 border-black/30 flex items-end justify-center overflow-hidden hover:cursor-pointer"
        onClick={showTrailer}
      >
        <img
          src={`/assets/trailer_thumbnail.png`}
          alt="Frameland – Gameplay Trailer"
          width={715}
          height={402}
          className="transition-transform hover:scale-105"
        />
        <img
          src={`/assets/video_play_button.png`}
          width={115}
          height={83}
          className="absolute mb-8 md:mb-20 ml-16 opacity-90 pointer-events-none"
        />
      </div>
      <YoutubeVideo hideTrailer={hideTrailer} isVisible={state === "shown"} />
    </>
  )
}

function YoutubeVideo({ hideTrailer, isVisible }) {
  const src = "https://www.youtube-nocookie.com/embed/T87JjxBXSng?si=SE4CGF3a9OW9HjPP"
  const title = "Frameland - Gameplay Trailer"
  const [width, setWidth] = useState(530)
  const [height, setHeight] = useState(315)

  function handleResize() {
    let windowWidth = 530
    if (typeof window !== "undefined") {
      windowWidth = parseInt(window.innerWidth)
    }
    if (windowWidth < 550) {
      setWidth(530)
      setHeight(315)
    } else if (windowWidth < 1000) {
      setWidth(530 * 1.4)
      setHeight(315 * 1.4)
    } else if (windowWidth < 1500) {
      setWidth(530 * 1.7)
      setHeight(315 * 1.7)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    setTimeout(handleResize, 10)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <Modal onClose={hideTrailer} isVisible={isVisible} maxWidth={width}>
      <div
        style={{ width: width, maxWidth: "100%" }}
        className="relative aspect-video drop-shadow-2xl border-2 border-white/30"
      >
        {isVisible && (
          <iframe
            className="absolute left-0 right-0 top-0 bottom-0"
            width="100%"
            height="100%"
            src={src}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </Modal>
  )
}
