import React, { useEffect, useState, useRef } from "react";
import FocusTrap from 'focus-trap-react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createPortal } from "react-dom";
import * as style from './Modal.module.css';

export function Modal({ onClose, isVisible, maxWidth = '100%', children }) {
	const closeButtonRef = useRef(null);
	const [focusTrapped, setFocusTrapped] = useState(false);
	let classes = style.modal;
	if (isVisible) {
		classes += ' ' + style.isActive;
	}

	useEffect(() => {
		setFocusTrapped(isVisible);
		if (isVisible) {
			const element = document ? document.body : null;
			if (element) {
				disableBodyScroll(element);
			}
			if (closeButtonRef) {
				closeButtonRef.current.focus();
			}
			return () => {
				enableBodyScroll(element);
				setFocusTrapped(false);
			};
		}
	}, []);


	const modal = (
		<FocusTrap active={focusTrapped}>
			<aside className={classes} aria-modal="true" tabIndex="-1" role="dialog" onKeyDown={handleKey}>
				<div
					className={style.modalBackground}
					onClick={hide}>
				</div>
				<div className={style.modalWrapper} style={{ maxWidth: maxWidth }}>
					<div className={style.modalContent} onClick={(e) => { e.stopPropagation() }}>
						{children}
					</div>
				</div>
				{onClose &&
					<button
						className={style.modalClose}
						aria-label="close"
						onClick={hide}
						ref={closeButtonRef}
					>
						Close
					</button>
				}
			</aside>
		</FocusTrap>
	);

	function hide() {
		if (onClose) onClose();
	}

	function handleKey(event) {
		if (event.keyCode === 27) {
			hide();
		}
	}

	if (typeof window !== 'undefined') {
		return createPortal(
			modal,
			document.getElementById("___gatsby")
		);
	}

	return null;
}

