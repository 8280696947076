import React from 'react';

export function Video({ name }) {
	return (
		<div className="video-container">
			<video playsInline muted autoPlay loop className="video">
				<source src={`/assets/about/${name}.mp4`} type="video/mp4" />
			</video>
		</div>
	)
}

export function VideoDescription({ textColor = 'text-gray-300', children }) {
	return (
		<p className={`text-base px-2 py-1 ${textColor}`}>
			{children}
		</p>
	)
}
