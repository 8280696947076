import React from 'react';

export function Box({ children, asBlock = false, isTransparent = true, className, style = {} }) {
	const customStyle = {
		display: asBlock ? 'block' : 'inline-block',
		borderImageSource: isTransparent ? "url(/assets/box_transparent.png)" : "url(/assets/box.png)"
	}
	let classes = 'flex justify-center';
	if (className) {
		classes += ' ' + className;
	}
	return (
		<div className={classes} style={{ ...defaultStyle, ...customStyle, ...style }}>
			{children}
		</div>
	)
}

const defaultStyle = {
	borderImageSource: "url(/assets/box_transparent.png)",
	borderImageRepeat: "round",
	borderImageSlice: "7 fill",
	borderImageWidth: "auto",
	display: "inline-block",
	padding: "7px",
	marginBottom: -1
};
