import React, { useState, useEffect } from 'react';
import { Box } from '../components/Box';
import { Section } from '../components/Section';
import { Video, VideoDescription } from '../components/Video';
import { Fade, Zoom } from "react-awesome-reveal";
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'

export function Intro() {
	return (
		<>
			<div className="clouds-top"></div>
			<Section bg="bg-fl-sky" color="text-fl-text-light" className="pb-12">
				<img src="/assets/symbol_circle.png" className="mb-4 opacity-50 block mx-auto" alt="" />
				<div className="fl-container relative mb-6">
					<Airship />
					<header className="text-center mx-auto w-128 max-w-full relative z-10">
						<div className="px-2">
							<Fade direction="up" triggerOnce cascade duration={800} fraction={0.2}>
								<h2 className="text-3xl font-serif text-fl-black">
									<Trans
										i18nKey="journeyTitle"
									>
										A <span className="text-fl-highlight-light">Journey</span> awaits
									</Trans>
								</h2>
								<p className="text-lg mt-4">
									<Trans i18nKey="journeyDesc">
										A mysterious incident throws <strong>Ryiu</strong> headfirst into a foreign
										world. Danger lurks around every corner as swarms of machines bring humanity to
										the brink of destruction. But not all have lost hope, and new alliances are
										waiting to be forged — all for one goal: to return home.
									</Trans>
								</p>
							</Fade>
						</div>
					</header>
					<ul className="max-w-lg lg:columns-2 gap-2 lg:max-w-2xl xl:max-w-3xl mx-auto my-6 relative z-10">
						<li className="mb-6">
							<Box className="w-full">
								<Video name="story1" />
							</Box>
							<VideoDescription textColor="text-gray-600">
								<Trans i18nKey="ryiuDesc">
									Play as Ryiu, a youth from Hammertal and the leader of the #1 bunch of ragtag
									misfits, the <strong>Talons</strong>!
								</Trans>
							</VideoDescription>
						</li>
						<li className="mb-6">
							<Box className="w-full">
								<Video name="story2" />
							</Box>
							<VideoDescription textColor="text-gray-600">
								<Trans i18nKey="friendsDesc">
									You're not alone! You'll meet new friends on your journey with a few tricks up
									their sleeves.
								</Trans>
							</VideoDescription>
						</li>
					</ul>
				</div>
				<CharacterSelection />
				<div className="symbols opacity-25 mt-8 -mb-4"></div>
			</Section>
			<div className="clouds-bottom -mt-4"></div>
		</>
	)
}

function Airship() {
	return (
		<div className="absolute z-10 animate-airship hidden lg:block xl:-ml-12" style={{ width: 298, height: 542, top: -120, left: "-6%" }}>
			<img src="/assets/about/airship.png" className="absolute" alt="Airship" />
			<img src="/assets/about/airship_left.gif" className="absolute" style={{ left: 10, top: 332 }} alt="Airship propeller" />
			<img src="/assets/about/airship_left.gif" className="absolute" style={{ left: 10, top: 418 }} alt="Airship propeller" />
			<img src="/assets/about/airship_right.gif" className="absolute" style={{ right: 10, top: 332 }} alt="Airship propeller" />
			<img src="/assets/about/airship_right.gif" className="absolute" style={{ right: 10, top: 418 }} alt="Airship propeller" />
		</div>
	)
}

const chars = ['ryiu', 'proto', 'three', 'yuri'];
const quaracterQuotes = {
	ryiu: "Leave it to Ryiu, fearless leader of the almighty Talons!",
	proto: "Yes, I’m a robot. No, I don’t harm people. Never have.",
	three: "Guess we'll have to trust each other for a while. How about I tag along?",
	yuri: 'I see. I was mistaken about you. Another oversight on my part, it seems.'
};
const quaracterDescriptions = {
	ryiu: "An adventurous youth from the quaint little town of Hammertal trying to find his way home.",
	proto: "Part dog, part dad friend, 100 % robot. Proto is on a mission to save his master.",
	three: "A mysterious woman you meet in prison, of all places. What could be her motive?",
	yuri: <span className="block">An ingenious AI developer with a rather insignificant&nbsp;task: to save mankind.</span>
};
const gifData = {
	ryiu: 'data:image/gif;base64,R0lGODlhKAAwAPQPANh4Tj4AAO7u7sBgAWAaAbhYPgAAAIA3BT09aqmpdDkxOBAQFIA+AGFpnO6hcQQCAoE4BDw8aiMQGqipdDkyPhQQFYA+AWBpnO6gcT0UnQAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQJIAAPACwAAAAAKAAwAAAE//DJSau9OOvNu/9gKI5kaZ5oqq6sFgQE8S5LO73xXLcvw8Q0m6T3IwR5r4CPlkseTUklY2Fo6ky0A4MY8/mazxDNxyV4i9dR9GtGR5070LrIfsPlsDY5kOgn6kkiOHpbfH6ALyJMbS99Ao8CfYBhHIt7jpB9WmBxHVSDNAUFAKQAootpHjSgBqKlpgUGC2AGIKEFMacGr7KiuQWUG7e/NK/DBKKytrsAL6QKCq/QpM4AwRrFzQHP0aXT2qTXwjQLrgAO6A6kyTS1I1S3pOnqsOSdirIGBweiogdGsu69I7evX4F99rAsQNegATwDEGk0RCeu0kIHDe3Zm+hAmZhyB3kPIEDQ8FXDkQWBCcQGct/IkqVOIkjpURWzfAteaNHyAme4lRmy2SOyx95PWxfRyXrZsMFIGvNqejIwjwZTmVSqAmUpsSm8BU0dwiPRDqxXcmETlshSJGAVAloqfhhTxF6XKe7ILhjAd4COVQH6DpDLUvDfnIH7yo0AACH5BAUgAAYALAYABgAaACoAAAX/oCGOZBAQhFlVZOuOJqqyb21aFrrWvHHnhF2vZfqtZEWhrYirPJCz2gpi+aFwOKRytMJZCVhglMgEZlPF9DZ9PqfVtBgYa5rYJ+5i6TT3Bu54YnpcFVdVfxMCigJ2eUJHc3WJi3ZUWjROcisFBQCeAJyQYyuaD5yfoAUPhWhOIpsFKKEPqKucsgWPFbcEoRWosLirrxISKxKeFBSoyp4mnkLF0sgAzZ/WzwArFRIY3hjFpwCrq56cq64Gxd/gu53a2+a52yLFqMXl4yu0+un11NCM8dv2C94Wbpw4QYCwjR80JzRIhEu4EF1BTxZd2EtFAEIxExEicArJrRgJbvIWgX4MEHJkhGkSqYWSduFCywImJppUJ7PAtJo3c0rgtLMetwfTVqqQVlJCzKTS0kAt2qJYzatXQ4akqlECVqxaXzp9wQ2oVm5hQ9bk1tXsVglpI9TkyjNNBAorFw7YO4BtVQl2xf7gO4AqN8JUknJbLOFBV8I4pibtypgx3R6S/fYIAQA7',
	proto: 'data:image/gif;base64,R0lGODlhPAAsAPQAAAAAABAQFGBoeOQAALwKNFlNSZRoZBk0QICIkGFGULCGdFlMSf8AAf+HIxk0QWBoeYGIkWFGUb0KNJRpZBg0QYCJkGFHUQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFKAALACwAAAAAPAAsAAAE/3DJSau9OOvNu/9gKI5kaZ5oqqZBkCQt2L7xSs1F7eG6vQQAhcJg6GFaRCHQN2kJicYL0iAEBJiSViHxLLa+4KmQdsXOuEODFdhar5OKRK7MBMpfRDIR6krktwlWWD99eAZ6e2d5LzB0Plp+h2hClJVxkWSDipKWnQpbi4Jmb1SepkRrjjZfAHCmnXxRKC0HB2evnltWtbIltAdWcridZLWiKkAICKxinqhsLcq9JNHLYK2lsF6s0qq+X8rBCQMDhnt7L+Rk4V+zbN3p5QXn53LkW9WsJi0CAvz+AYgQIECvoIGBz/oBUehtB4B+ViAGPEjQ4DmEXhgypBaAoZUtxMwCsXkRMpVEjgxvWcL3haQlMgsB7kv1pV+XZ1PgSAzTMMS1NjbTxMKmE2Cbdix4PhQAiNEdRjuh9eQotaMAp1jvRAVTJ0CBOcmUiR3LTsscTV5zrCHL1prZad++ygXLxs1RIHO/whVhdi7Pv1/ynk1a9adgvYDvuksc+HDhu3s3VLMyVmLKMBoFjM03VTKAbpUBbgXqb+nmAOyo1uzHepctR7/+sRZAs7MGZlb7FQBoDHYAXrlZQ54FoJbx1xyAHO+96vfyYxl+HYeuIQIAIfkEBSgAAgAsDAAGACUAJgAABf+gII5RNJ5oKpZqGi1tTMJybd94Xi+mTvY1lm9FC/J6QheyBJQxkUWUkBmN8VbN1jQCsG1tV8ErMCybfd2WwxEIJG+lAGAdo4uzu0h7TVZBIG1tDAw2gyd/aSl/gQGGNYNtAAF/MYsAg5iEKpmQk4Ati42cLZwMbYiBAg8PbauJDQ02sCJyq62sqyK2I7M1vbqsAa62kWEnb29EcrfCrJJueGOByXcnri25KHLYDzd9qt0t3yPEz5Ep5s1P60zZtOco6bUPRyVH9+U4C1F//f79IiTtu7GP0b+DEMwNlLGv4YJIEBNJeuZwoYqKDxlp3IjRhxyMFs+kA1nlxDgBlFA/9rMFgNhGYqv8SVoUyh9MViqyxex3CgKKVUDDrUl0gk+zoDGCEuOjYuhRoKlQrJnqgKiMAFTtNM2qI6sDFCEAACH5BAUoAAQALAwABgAlACYAAAX/ICGOljWeaCqWamq2MBmn0zSj9Y3bupj3hFctQCwaiT/WTfmLJXuWRalWQtYmxOkkqpyVtC/CFRsAf3VRahhmDiwtarQa0Gvq7LcAfjYswigUAQBdOlIAAYB/gWULQEEWRIB0KhUVh4JWLTWHJ5VulBVHAHs+ZEUAlTCeZRYDA1pjaq5VAZWTKbasswuxV1GuUkSrggQCAkTGVhISvb3LmwHJAMnGIsl6E8/NY9rIx9HH14eEK2vkj5fg099EUinBReeEySiX1WJXl6NX1gKOxf7w9bHy4xqRdCfSHTQm5YvDL/dEIERxxNvDLw0XGLuk49aISiBDghTx6d8lkShDVf1TQYzkoZctV8qcOaIkEE4zTsXAxNOjiFQEQm4EV9Gbt5DCQq0S+s3gkWvGkNaqgMKY1YCJUgCQBO4qjKvXsqYA5O3qwbGA0t5Mq7YFW7E63gY6EQIAOw==',
	three: 'data:image/gif;base64,R0lGODlhKAAoAPQTANl5TzYpSz0BAWAaAPjhqrlYPgAAAHoAMv/Ho4A+AU0/We6hcL0KNDszQBEQFE9JUSMQGvaZaDw1QwEBAXoAM//Hok1AWIE+Ae6gcDs0QRIQFE5HUSIQGvaZaT82RzJK0iH5BAkjABMALAAAAAAoACgAAAX/4CSOJOmcaHqWbOuKauy8dHsmyaDvOr7WwFuOt8MZZsCXkDgQOJ8+ZLK0JD6hid/UZODhrjynw7ClOrwJ8E4s3Z6c3ydgDnCGBdrkWxB30usCd3lBXWsCC4iJiGwnR2Und4qKYkcoZTqImAtjikeZA5+XoAuaJ4qmpKM6ogUFmgENig2xqa2rWzq2o7OyAZ+6ZTCVBweICMcIiMRjY8EjKcTGyBELy5bOEycM2wzE3t7cDINuBuHf3+HjQSjo4dzWjsHMDu3u3QeVbVMnCgoOEAECnPMWsJ+6GvwUQAAocOCBgv706XEQIcLCgCiOVIJ4kMaYiv8C5kvBUeIUCP8YdwZcuTJkgI41Fl5kSXNmPGwTZDLsx3IhAQIgTeqRCbEnhJ9BcZ4IqFPnEZFC9xlg2nTh0pdR3aTUSfUmzmcGqlKFuQ9CAwkPFBINoPPriIVnHzzgylam261yZ8kUAEtvViA680qw6mCWXghuPaLI24AsthSCYYYAACH5BAUjAAYALAgAAgAYACYAAAX/oCGOWmme5aiu5dS+6aqW1zXc+F3HMm3nuN2kp6kBB4KkcqeZFX855fLCM2gmuZo0KqiWspctLnkVlZJaJWANSHJP6LCa7R53W1yMfq8nm1xfdnx8ZICBejeDey6IA42JGJCKJY2PAwUFNyUcHHwZAY2YOaIDGpydGB0YGRmhmQNmWDh6FbUVli5OObS2ljEnFMEURzfBJ7ElwsPExoBmGhYWpgEBysrUHhtlVtAWnNTWwtTRvxodHdMBKCbj0k1WE+fpgPQa7VUcphzU/P0B3+reiTi1z1+/fNSqDDzV7iAHAgTO5RpBsCE/ThDlCYRHjSDBK9QmOunokVOJhBudQZQkqZCFvor/OIhkwYFVNJgEZSxktWGDSQEkOfU41dNDhlMaWAU4ykEGwZ4ZPBBkZVSozquGoGZoefWEhQ1RY4QAADs=',
	yuri: 'data:image/gif;base64,R0lGODlhKAAtAPMAAAAAAEw+WmAaADYoTBAQFO7EJGRYaP/qdu6gcYF+hLj/8dh4Tu6gcIB+hLj/8Nl4TyH/C05FVFNDQVBFMi4wAwEBAAAh+QQJIAAMACwAAAAAKAAtAAAE/5DJSau9OGs2uifEJmbeF46o1AVBB6YwN7DuGW8lC5b1beWBHe/lk4Bos1YS2fMdlUgWc0C8AVdKnsnqiWa1A4BtBESCntoquSutoYdjkdBMQNgR7yZqrgTd8QRTYjBCNQsLf4diJWpyAIwEh4kLhVRxG34Ih5J/d5uICIMpmZ+dnpt2jZiRC1pilR2Klxogh2kEizyHqo4dCr8KJcAKuUVCw8LAQkVGBCV/HWJ/vCO4z3c10wDGzgMC3+Dh07PV3eHnAn+ihIFSLAXwBe7u3PMB8fL2ATdPXgf/B7yw4NfOH0CB+2Jg4QMwYJ8zXHQ8StIQyStyGL79+YYFH50bGljvcEzi8SHBjQKelKTmCCWud/F0YCyHC4QBA2dq4rrJEtNFAjxznrm5jhmIBEgTXEyaoOceAExzRp25p11QMTclMpvwhOhQAzK3Hk0q88lUbkzLAmBxNkMEACH5BAUgAAgALAYAAQAaACwAAAT/EMlJh72E0M3nxVonWkFgZWKKkOaAqttXZt8ZjtdM1O69ZSUWKwjiAFvEEtHGkSFbPKYn94xeACEnMXPkvbQtLmFZRNAGWwJjzeiWz2k2A0vGSs62x0Oux/fuAB8ZenwPBIFvagw8WIc8a3Zmil5cj20hgw8WDpwOH50OZy+Zm52fnY5/E4d+ch9ykXeVF660bC8UhwIChGx6u3K4qwS7vWu/AsE+sha7zs5DASJnz8/RRmNKJQXcBdra2N8B3d7i0sPaLAfrB0SNPkdJAeztYVwVaAFw8+xpTfmpStBz5wMYm10syBHhYHANwnwKW2CTs+tIRGGAKAo4tK2bjmkZQxoZMCCmEYCRGM28I4CSlZiRsTpkaECzgZiaNpf9AIDzZs2UubKhFGlgBgAVR1oeGvlRJgGcJd6V6KlzZk0dR6hKiAAAOw=='
};

function CharacterSelection(props) {
	const [activeChar, setActiveChar] = useState('ryiu');
	const [isAnimated, setAnimated] = useState(false);

	// preload gifs
	useEffect(() => {
		for (let index = 0; index < chars.length; index++) {
			let image = new Image();
			image.src = `/assets/chars/${chars[index]}.png`;
		}
	}, []);

	function fadeInRyiu() {
		setTimeout(() => {
			selectCharacter('ryiu');
		}, 1200);
	}

	return (
		<div className="overflow-hidden pt-8 text-stone-600 bg-stone-500/20 text-center">
			<div className="mx-auto max-w-sm text-center">
				<Fade triggerOnce direction="up" delay={100} duration={800} fraction={1.0} onVisibilityChange={() => fadeInRyiu()}>
					<h3 className="text-md font-bold uppercase tracking-widest">
						<Trans i18nKey="playableChars">
							Playable Characters
						</Trans>
					</h3>
				</Fade>
				<ul className="flex justify-center">
					{chars.map((char) => (
						<li key={char} className="relative w-16 h-16 m-1 flex justify-center items-end hover:cursor-pointer" onClick={() => selectCharacter(char)}>
							<Char
								char={char}
								activeChar={activeChar}
								shouldAnimate={isAnimated}
							/>
						</li>
					))}
				</ul>
			</div>
			<p className="max-w-[21rem] mx-auto mt-4">
				<Trans i18nKey={`${activeChar}Description`}>
					{quaracterDescriptions[activeChar]}
				</Trans>
			</p>
			<Box className="mt-6 -ml-2 relative" style={{ width: 'calc(100% + 1rem)' }}>
				<div className="flex items-center justify-center relative pl-2" style={{ minHeight: '4.5em' }}>
					<img
						src={`/assets/facesets/${activeChar}.png`}
						alt={`Face of ${activeChar}`}
						className="inline-block -mt-6 self-end"
					/>
					<p className="italic font-serif py-2 px-4 max-w-sm text-fl-sky md:text-lg text-left">
						<Trans i18nKey={`${activeChar}Quote`}>
							“{quaracterQuotes[activeChar]}”
						</Trans>
					</p>
				</div>
			</Box>
		</div>
	)

	function selectCharacter(char) {
		setActiveChar(char);
		setAnimated(true);
		const timeout = {
			ryiu: 600,
			proto: 1200,
			three: 700,
			yuri: 800
		};
		setTimeout(() => {
			setAnimated(false);
		}, timeout[char]);
	}
}

function Char({ char, activeChar, shouldAnimate }) {
	const isGif = shouldPlayGif();

	return (
		<>
			{isActive() && (
				<div className="absolute left-0 bottom-0 right-0">
					<Zoom duration={200}>
						<img src="/assets/chars/selected.png" className="mx-auto " alt="" />
					</Zoom>
				</div>
			)}
			<img
				src={`/assets/chars/${char}.png`}
				alt={`Character ${char}`}
				className="relative bottom-1"
				style={{ display: isGif ? 'none' : 'inline-block' }}
			/>
			{isGif && (
				<img src={gifData[char]} alt={`Character ${char}`} className="relative bottom-1" />
			)}
			{isActive() && (
				<p className="absolute -bottom-5 uppercase text-xs tracking-wider">
					{activeChar !== 'three' && activeChar}
					{activeChar === 'three' && '???'}
				</p>
			)}
		</>
	)

	function isActive() {
		return activeChar === char;
	}

	function shouldPlayGif() {
		return isActive() && shouldAnimate;
	}
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
